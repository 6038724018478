import React from "react";

import "./Label.scss";

interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  rightChildren?: React.ReactNode;
  required?: boolean;
}

export const Label = ({
  className,
  children,
  rightChildren,
  required,
  ...props
}: LabelProps) => {
  return (
    <label className="labelRoot" {...props}>
      <span>
        {children}
        {required && <span className="required">*</span>}
      </span>
      &nbsp;
      {rightChildren}
    </label>
  );
};
