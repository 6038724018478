import { RadioGroup, Radio, Input, Button } from "../../../../components";
import { useForm } from "../../../../lib/use-form";
import { getSchema, TestValue } from "./getSchema";
import {
  HealthTestQuestionAndAnswers,
  AnswerType,
  HealthTestType,
} from "../../types";
import { isString } from "lodash";
import { getSubmitValues } from "./getSubmitValues";
import { useSentTestResult } from "../../hooks";

const CURRENT_NODE_PATH = "questionsAndAnswers";

interface Props {
  testType: HealthTestType;
  questionsWithAnswersList: HealthTestQuestionAndAnswers[];
}

export const TestForm = ({ questionsWithAnswersList, testType }: Props) => {
  const sentTesResult = useSentTestResult();
  const { nestedField, formProps, submitProps, values } = useForm({
    getSchema,
    onSubmit: (values: TestValue) =>
      sentTesResult(getSubmitValues(testType, values)),
    initialValues: {
      questionsAndAnswers: questionsWithAnswersList.map(
        ({ question, answerType, isRequired, inputFieldName }) => ({
          question,
          answerType,
          answer:
            null as unknown as TestValue["questionsAndAnswers"][0]["answer"],
          isRequired,
          inputFieldName,
        })
      ),
    },
  });

  const requiredQuestions =
    values.questionsAndAnswers?.filter(({ isRequired }) => isRequired) || [];

  const isDisabledButton =
    requiredQuestions.length > 0
      ? requiredQuestions?.every(({ answer }) => isString(answer))
      : values.questionsAndAnswers?.some(({ answer }) => isString(answer));

  return (
    <form {...formProps}>
      {questionsWithAnswersList.map(
        // eslint-disable-next-line array-callback-return
        ({ question, answerType, answers, isRequired }, index: number) => {
          const currentNodePath = `${CURRENT_NODE_PATH}.${index}`;

          if (answerType === AnswerType.pick) {
            return (
              <RadioGroup
                {...nestedField<string>(`${currentNodePath}.answer`)}
                key={question}
                required={isRequired}
                label={question}
              >
                {answers.map((item) => (
                  <Radio key={item} label={item} value={item} />
                ))}
              </RadioGroup>
            );
          }
          if (answerType === AnswerType.input) {
            return (
              <Input
                {...nestedField<string>(`${currentNodePath}.answer`)}
                key={question}
                type="number"
                required={isRequired}
                placeholder="Vui lòng điền câu trả lời"
                label={question}
              />
            );
          }
        }
      )}
      <Button {...submitProps} children="Send" disabled={!isDisabledButton} />
    </form>
  );
};
