import React from "react";
import { TestForm } from "../TestForm";
import { HealthTest, HealthTestType } from "../../types";
import { Warning } from "../../../../assets/icons";
import "./TestPage.scss";
interface Props {
  data: HealthTest;
  testType: HealthTestType;
}

const appendDisabledSelectToClassName = (className: string) =>
  `${className} disabledSelect`;

export const TestPage = ({ data, testType }: Props) => {
  const testPageLayoutCN = appendDisabledSelectToClassName("testPageLayout");
  const testTitle = data.name;

  return (
    <div className={testPageLayoutCN}>
      <h2 className="testPageTitle">{testTitle}</h2>
      <h3 className="testPageDescription">{data.description}</h3>
      {data.info && <div>{data.info}</div>}
      <TestForm
        questionsWithAnswersList={data.questionsWithAnswersList}
        testType={testType}
      />
      <div className="testPageWarning">
        <Warning className="testPageIcon" />
        {data.warning}
      </div>
    </div>
  );
};
