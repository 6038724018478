import React, { useContext } from 'react'

import { UseFormResult } from './types'

const Context = React.createContext({})

interface ProviderProps<Values extends object> {
  children: React.ReactNode
  value: UseFormResult<Values>
}

export const FormContextProvider = <Values extends object>({
  children,
  value,
}: ProviderProps<Values>) => {
  return <Context.Provider children={children} value={value} />
}

export function useFormContext<Values extends object>() {
  const ctx = useContext(Context)

  return ctx as UseFormResult<Values>
}
