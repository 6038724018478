import axios from "axios";
import { getCalculateHealthTestResult } from "../queries";
import { CalculateHealthTestResultInput } from "../types";
import { useHistory } from "react-router-dom";

export const useSentTestResult = () => {
  const history = useHistory();
  return async (values: CalculateHealthTestResultInput) =>
    axios
      .post(
        process.env.REACT_APP_BASE_URL,
        {
          query: getCalculateHealthTestResult(),
          variables: {
            input: values,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.errors) {
          alert(response.data.errors.map((error: any) => error.message));
        } else {
          history.push({
            pathname: `/result`,
            state: response.data.data.calculateHealthTestResult,
          });
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
};
