import React from "react";

import { Label } from "../Label";

import "./FormField.scss";
import { FormFieldProps } from "./types";

export const FormField: React.FC<FormFieldProps> = ({
  className,
  error,
  helperText,
  children,
  label,
  labelRightChildren,
  description,
  showError,
  labelClassName,
  required,
  fieldLeftSibling,
  ...rest
}: FormFieldProps) => {
  return (
    <div className="formFieldRoot">
      {Boolean(label) && (
        <Label
          children={label}
          className={labelClassName}
          required={required}
          rightChildren={labelRightChildren}
        />
      )}
      {Boolean(description) && (
        <div className="caption mb-1">{description}</div>
      )}

      {fieldLeftSibling ? (
        <div className="row">
          {fieldLeftSibling}
          <div className="childrenContainer col">{children}</div>
        </div>
      ) : (
        children
      )}

      {!!error && showError && (
        <div children={error as React.ReactNode} className="formFieldError" />
      )}
    </div>
  );
};
