import React from 'react'

import { IconProps } from './types'

export const RadioUnchecked = ({ color, ...props }: IconProps) => (
  <svg
    {...props}
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.5" cy="8" r="7.5" stroke={color} />
  </svg>
)
