import { isNil } from "lodash";
import React, { Children } from "react";

import { FormField, FormFieldProps, getFormFieldProps } from "../FormField";

import "./Radio.scss";

export interface RadioGroupChildProps<T extends string | number | boolean> {
  value: T;
  name?: string;
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
}

export interface RadioGroupProps<T extends string | number | boolean>
  extends FormFieldProps {
  name?: string;
  className?: string;
  value?: T;
  onChange: (v: T) => void;
  children: React.ReactElement<RadioGroupChildProps<T>>[];
  disabled?: boolean;
  formFieldClassName?: string;
}

export const RadioGroup = <T extends string | number | boolean>({
  children,
  name,
  value,
  className,
  onChange,
  disabled,
  formFieldClassName,
  ...rest
}: RadioGroupProps<T>) => (
  <FormField {...getFormFieldProps({ ...rest, className: formFieldClassName })}>
    <div className="radioGroupContainer">
      {Children.map(children, (child) =>
        React.cloneElement(child, {
          name,
          disabled: disabled || child.props.disabled,
          checked: value === child.props.value,
          onChange: () => {
            return !isNil(child.props.value) && onChange(child.props.value);
          },
        })
      )}
    </div>
  </FormField>
);
