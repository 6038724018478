import React, { FC, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Button, Snackbar, TextField } from "@mui/material";
import axios from "axios";
import { object, ref, string } from "yup";
import "./PasswordForm.css";

interface PasswordFormProps {
  linkId: string;
}

type Values = { password: string; passwordConfirm: string };

const schema = object().shape({
  password: string()
    .min(8, "Password must be at least 8 characters long")
    .max(30, "Password is too long")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,30}$/,
      "Password must have at least one uppercase letter and at least one number"
    )
    .required("Required"),
  passwordConfirm: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Required"),
});

export const PasswordFormComponent: FC<PasswordFormProps> = ({ linkId }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorSnackbarText, setErrorSnackbarText] = useState("");

  if (isSubmitted) {
    return <h1>Password set successfully</h1>;
  }

  const onSubmit = (values: Values, helpers: FormikHelpers<Values>) => {
    axios
      .post(process.env.REACT_APP_BASE_URL, {
        query: `
            mutation {
              updateDoctorPassword(linkId: "${linkId}", newPassword: "${values.password}")
            }
          `,
      })
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(() => {
        setErrorSnackbarText("Failed to update password");
        helpers.setSubmitting(false);
      });
  };

  return (
    <>
      <h1>Set password</h1>
      <Formik
        initialValues={{ passwordConfirm: "", password: "" }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          errors,
          handleChange,
          handleBlur,
          values,
          touched,
        }) => (
          <Form>
            <TextField
              name="password"
              type="password"
              fullWidth
              label="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              name="passwordConfirm"
              type="password"
              fullWidth
              label="Confirm password"
              value={values.passwordConfirm}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
              helperText={touched.passwordConfirm && errors.passwordConfirm}
            />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={
                isSubmitting ||
                !values.password ||
                !!errors.passwordConfirm ||
                !!errors.password
              }
            >
              Set password
            </Button>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={!!errorSnackbarText}
        autoHideDuration={6000}
        onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === "clickaway") {
            return;
          }

          setErrorSnackbarText("");
        }}
        message={errorSnackbarText}
      />
    </>
  );
};
