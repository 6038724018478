import { useEffect, useState } from "react";
import { PasswordFormComponent } from "./PasswordFormComponent";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { LinkExpiredOrInvalid } from "./LinkExpiredOrInvalid";
export const PasswordFormContainer = () => {
  // TODO: move this out for health tests
  const [isLinkValid, setIsLinkValid] = useState<boolean>();
  const [linkId, setLinkId] = useState<string>();

  useEffect(() => {
    const linkIdValue = new URL(window.location.href).searchParams.get(
      "linkId"
    );
    if (!linkIdValue) {
      setIsLinkValid(false);
      return;
    }

    setLinkId(linkIdValue);

    axios
      .post(process.env.REACT_APP_BASE_URL, {
        query: `
           query {
             checkLinkHasBeenExpired(linkId: "${linkIdValue}")
           }
         `,
      })
      .then(({ data }) => {
        setIsLinkValid(!data.data.checkLinkHasBeenExpired);
      })
      .catch(() => {
        setIsLinkValid(false);
      });
  }, []);

  if (isLinkValid === undefined) return <CircularProgress />;
  if (!isLinkValid || !linkId) return <LinkExpiredOrInvalid />;

  return <PasswordFormComponent linkId={linkId} />;
};
