import { SchemaOf, array, object, string, boolean, mixed } from "yup";
import { AnswerType } from "../../types";
import { enumerate } from "../../../../lib";

export interface TestValue {
  questionsAndAnswers: QuestionsAndAnswersField[];
}

export interface QuestionsAndAnswersField {
  question: string;
  answerType: AnswerType;
  answer?: string;
  isRequired: boolean;
}

export const getSchema = (): SchemaOf<TestValue> => {
  return object({
    questionsAndAnswers: array().of(questionsAndAnswersSchema),
  });
};

export const questionsAndAnswersSchema: SchemaOf<QuestionsAndAnswersField> =
  object({
    question: string().required(),
    answer: mixed().when("isRequired", (isRequired) =>
      isRequired
        ? string().required("Please, answer the question").nullable()
        : string().nullable()
    ),
    isRequired: boolean().required(),
    answerType: enumerate(AnswerType).required(),
  });
