import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const TestPageResult = () => {
  const location = useLocation();
  const dataStr =
    "data:text/json;charset=utf-8," + encodeURIComponent(location.state as any);
  const link = document.createElement("a");
  link.href = dataStr;
  link.setAttribute("download", "result.json");

  useEffect(() => {
    link.click();
  }, [link]);

  return null;
};
