import "./Button.scss";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export type ButtonColor = "white" | "danger" | "monochrome";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

export const Button = ({
  children,
  type = "button",
  onClick,
  color,
  loading,
  disabled,
  ...props
}: ButtonProps) => {
  const areChildrenPassed =
    React.Children.count(children) > 0 && Boolean(children);

  return (
    <button
      disabled={disabled}
      className={`buttonRoot ${disabled ? "disabledButton" : " "}`}
      type={type}
      onClick={(e) => [onClick?.(e), e.currentTarget.blur()]}
      {...props}
    >
      {loading && (
        <div className="buttonSpinnerWrapper">
          <CircularProgress
            className="buttonSpinnerColor"
            color="inherit"
            size="xs"
          />
        </div>
      )}
      {areChildrenPassed && children}
    </button>
  );
};
