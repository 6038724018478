import { get, set, cloneDeep, isEqual, isObject, merge } from "lodash";
import { useMemo } from "react";

import {
  FormProps,
  SubmitProps,
  FieldProps,
  FormConfig,
  UseFormResult,
  Formik,
  DeepPartial,
} from "./types";
import { useFormBase } from "./useFormBase";

let counter = 0;

export function useForm<Values extends object>(
  config: FormConfig<Values>
): UseFormResult<Values> {
  const formId = useMemo(() => `form-${counter++}`, []);

  const { formik, lastSubmitValues, handleSubmit } = useFormBase(config);

  const submitProps: SubmitProps = {
    disabled: formik.isSubmitting,
    loading: formik.isSubmitting,
    form: formId,
    type: "submit",
  };

  const formProps: FormProps = {
    id: formId,
    onSubmit: handleSubmit,
  };

  const validateForm = async () => {
    const errorMessages = await formik.validateForm(formik.values);
    if (Object.keys(errorMessages).length) {
      /* to highlight incorrect fields */
      handleSubmit(undefined);
      return false;
    }

    return true;
  };

  /**
   *  Type safe, but can be used only for flat fields
   */
  const flatField = <Name extends keyof Values>(
    name: Name
  ): FieldProps<Values[Name]> => {
    const dirtySinceLastSubmit =
      lastSubmitValues?.[name] !== formik.values?.[name];
    const showError = !!formik.submitCount && !dirtySinceLastSubmit;

    return {
      error: showError ? (formik.errors[name] as string) : undefined,
      onChange: (value?: Values[Name]) =>
        formik.setFieldValue(name as string, value),
      value: formik.values[name],
    };
  };

  /**
   *  Not type safe, but can be used for complex fields
   */
  const nestedField = <T>(name: string): FieldProps<T> => {
    const dirtySinceLastSubmit = isDirtySinceLastSubmit(
      get(lastSubmitValues, name),
      get(formik.values, name)
    );
    const showError = !!formik.submitCount && !dirtySinceLastSubmit;
    const error = get(formik.errors, name);

    return {
      error: showError ? error : undefined,
      onChange: (value?: unknown) => {
        const nextValue = setIn(formik.values, name, value);
        const flatName = name.split(".")[0];
        formik.setFieldValue(flatName, nextValue[flatName as keyof Values]);
      },
      value: get(formik.values, name),
    };
  };

  return {
    formik: formik as unknown as Formik,
    submitProps,
    formProps,
    validateForm,
    field: flatField,
    values: formik.values as DeepPartial<Values>,
    nestedField,
    initialValues: config?.initialValues,
  };
}

export function setIn<T extends object>(
  obj: T,
  path: string,
  value: unknown
): T {
  const clone = cloneDeep(obj);
  return set(clone, path, value);
}

export function multipleSetIn<T extends object>(
  obj: T,
  values: Record<string, unknown>
): T {
  const clone = cloneDeep(obj);
  for (const [path, value] of Object.entries(values)) {
    set(clone, path, value);
  }
  return clone;
}

function isDirtySinceLastSubmit<T>(lastSubmitValue: T, value: T) {
  if (isEqual(lastSubmitValue, value)) return false;

  if (isObject(lastSubmitValue) || isObject(value)) {
    return !isEqual(value, merge(lastSubmitValue, value));
  }

  return true;
}
