import { useState } from 'react'

export function useFocus() {
  const [focused, setFocused] = useState<boolean>(false)

  const focusProps = {
    onBlur: () => setFocused(false),
    onFocus: () => setFocused(true),
  }

  return { focused, focusProps }
}
