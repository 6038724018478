import React from "react";

import { IconProps } from "./types";

export const Warning = ({ color = "#ee933f", ...props }: IconProps) => {
  return (
    <svg
      fill="none"
      height="19"
      viewBox="0 0 21 19"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.22222 18.9814H18.7778C19.1403 18.9788 19.4968 18.8846 19.816 18.7072C20.1352 18.5297 20.4075 18.2743 20.6091 17.9632C20.8107 17.6521 20.9356 17.2949 20.9727 16.9226C21.0099 16.5503 20.9582 16.1743 20.8222 15.8273L12.9333 1.77712C12.7412 1.41858 12.4594 1.11972 12.1175 0.911617C11.7755 0.703509 11.3858 0.59375 10.9889 0.59375C10.5919 0.59375 10.2022 0.703509 9.86027 0.911617C9.51832 1.11972 9.23661 1.41858 9.04444 1.77712L1.15555 15.8273C1.02215 16.1663 0.969253 16.5333 1.00127 16.8978C1.03329 17.2622 1.1493 17.6134 1.33961 17.9221C1.52992 18.2308 1.78901 18.488 2.09525 18.6723C2.40149 18.8566 2.74599 18.9626 3.09999 18.9814"
        fill={color}
      />
      <path
        d="M11 14.3895V14.401M11 7.50781V9.80172V7.50781Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
