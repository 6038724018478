import { useFormik } from "formik";
import { useRef, useState } from "react";

import { FormConfig } from "./types";

const useRerender = () => {
  const [, set] = useState(false);
  return () => set((v) => !v);
};

export function useFormBase<Values extends object>(config: FormConfig<Values>) {
  const rerender = useRerender();
  const lastSubmitValues = useRef<Partial<Values> | null>(null);

  const formik = useFormik({
    ...config,

    enableReinitialize: true,
    initialValues: (config.initialValues ?? {}) as Values,

    validationSchema: config.getSchema?.(),

    onSubmit: async (values, formikHelpers) => {
      lastSubmitValues.current = values;
      rerender();
      const errors = await config.onSubmit(values, formikHelpers);
      if (errors) {
        for (const field in errors) {
          formikHelpers.setFieldError(field, errors[field]);
        }
      }
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    lastSubmitValues.current = formik.values;
    formik.handleSubmit(e);
  };

  return { formik, lastSubmitValues: lastSubmitValues.current, handleSubmit };
}
