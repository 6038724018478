import { TestValue, QuestionsAndAnswersField } from "./getSchema";
import {
  HealthTestType,
  AnswerType,
  CalculateHealthTestResultInput,
} from "../../types";
import { compact, toNumber } from "lodash";

type QuestionsAndAnswers = QuestionsAndAnswersField & {
  inputFieldName?: "age" | "height" | "weight" | "childAge";
};

export const getSubmitValues = (
  testType: HealthTestType,
  values: TestValue
): CalculateHealthTestResultInput => {
  const questionsAndAnswersResult = values.questionsAndAnswers.map(
    // eslint-disable-next-line array-callback-return
    ({ question, answerType, answer }: QuestionsAndAnswers) => {
      if (answerType === AnswerType.pick && answer)
        return {
          answerType,
          question,
          answer,
        };
    }
  );

  const getAdditionalInput = (type: HealthTestType) =>
    testType === type
      ? Object.assign(
          {},
          ...compact(
            values.questionsAndAnswers?.map(
              // eslint-disable-next-line array-callback-return
              ({ answerType, answer, inputFieldName }: QuestionsAndAnswers) => {
                if (answerType === AnswerType.input && inputFieldName)
                  return {
                    [inputFieldName]: toNumber(answer),
                  };
              }
            )
          )
        )
      : null;

  const additionalInput = {
    parentForChildrenTest: getAdditionalInput(
      HealthTestType.parent_for_children
    ),
    diabetesPreventionTest: getAdditionalInput(
      HealthTestType.diabetes_prevention
    ),
  };

  return {
    additionalInput: additionalInput as any,
    questionsAndAnswers: compact(questionsAndAnswersResult),
    type: testType,
  };
};
