export const getHealthTestByType =
  () => `query getHealthTestByType($type: HealthTestType!) {
  getHealthTestByType(type: $type){
    type
    name
    description
    warning
    info
    language
    questionsWithAnswersList{
      question
      answerType
      answers
      isRequired
      inputFieldName
    }
  }
}`;

export const getCalculateHealthTestResult =
  () => `query calculateHealthTestResult($input: CalculateHealthTestResultInput!) {
  calculateHealthTestResult(input: $input)
}`;
