import { useParams } from "react-router-dom";
import { HealthTestType, HealthTest } from "../types";
import { useEffect, useState } from "react";
import { ErrorState } from "../components/ErrorState";
import { CircularProgress } from "@mui/material";
import { getHealthTestByType } from "../queries";
import { TestPage as TestPageComponent } from "../components/TestPage";
import axios from "axios";

export const TestPage = () => {
  const { testType } = useParams<{ testType: HealthTestType }>();

  const [data, setData] = useState<{
    data?: HealthTest;
    error?: string;
  } | null>(null);

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_BASE_URL,
        {
          query: getHealthTestByType(),
          variables: {
            type: testType, //set testType here
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setData({ data: response.data.data.getHealthTestByType });
      })
      .catch((error) => {
        setData({ error: error.code });
      });
  }, [testType]);

  if (data?.error) return <ErrorState error={data?.error} />;
  if (!data?.error && !data?.data) return <CircularProgress />;

  return data.data ? (
    <TestPageComponent data={data.data} testType={testType} />
  ) : null;
};
