import { FormFieldProps, PlainError, FormFieldError } from "./types";

export const formatError = (error?: FormFieldError): PlainError => {
  if (error === null) {
    return null;
  }

  if (typeof error === "object") {
    console.error("Got error of type: object", error);

    return JSON.stringify(error);
  }

  return error ?? null;
};

export const getFormFieldProps = <T extends FormFieldProps>({
  error,
  helperText,
  label,
  description,
  className,
  labelRightChildren,
  showError = true,
  labelClassName,
  required,
  fieldLeftSibling,
  ...rest
}: T): FormFieldProps => {
  return {
    label,
    helperText,
    error: formatError(error),
    description,
    className,
    labelRightChildren,
    showError,
    labelClassName,
    fieldLeftSibling,
    required,
    "data-test-id": rest["data-test-id"],
  };
};
