import { omit } from "lodash";
import React from "react";
import { useFocus, useHover } from "../../hooks";

import { FormField, FormFieldProps, getFormFieldProps } from "../FormField";

type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export enum InputEventType {
  Change = "change",
  Clear = "clear",
}

export interface InputProps
  extends Omit<HTMLInputProps, "onChange" | "value">,
    FormFieldProps {
  success?: boolean;
  onChange: {
    (
      v: string,
      e: React.ChangeEvent<HTMLInputElement>,
      type: InputEventType.Change
    ): void;
    (
      v: string,
      e: React.ChangeEvent<HTMLInputElement> | null,
      type: InputEventType.Clear
    ): void;
  };
  value?: string | null;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  rightAdornment?: React.ReactNode;
  inputClassName?: string;
  inputWrapperClassName?: string;
  inputFocusedClassName?: string;
  inputHoveredClassName?: string;
  borderNone?: boolean;
  children?: React.ReactNode;
  isClearable?: boolean;
}

const InputBaseWithoutRef = (
  {
    onChange,
    success,
    endAdornment,
    startAdornment,
    inputClassName,
    inputFocusedClassName,
    onFocus,
    children,
    onBlur,
    value = "",
    borderNone = false,
    width,
    inputWrapperClassName,
    inputHoveredClassName,
    isClearable,
    rightAdornment,
    ...props
  }: InputProps,
  ref:
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
) => {
  const { focused, focusProps } = useFocus();
  const { hovered, hoverProps } = useHover();

  const inputProps = omit(props, [
    "required",
    "labelClassName",
    "labelRightChildren",
    "fieldLeftSibling",
  ]);

  return (
    <FormField {...getFormFieldProps(props)}>
      <div
        {...hoverProps}
        className={`
          ${inputWrapperClassName}
          ${focused ? inputFocusedClassName : ""}
          ${hovered ? inputHoveredClassName : ""}
        `}
      >
        <div className="inputInner">
          {startAdornment}
          <input
            {...inputProps}
            className={inputClassName}
            ref={ref}
            value={value ?? ""}
            onBlur={(e) => [focusProps.onBlur(), onBlur?.(e)]}
            onChange={(e) =>
              onChange(e.currentTarget.value, e, InputEventType.Change)
            }
            onFocus={(e) => [focusProps.onFocus(), onFocus?.(e)]}
          />
          {endAdornment}
        </div>
        {children}
      </div>
      {rightAdornment}
    </FormField>
  );
};

export const InputBase = React.forwardRef(InputBaseWithoutRef);
