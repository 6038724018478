import React from "react";

import "./Input.scss";
import { InputBase, InputProps } from "./InputBase";

export type InputRef =
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null;

const InputWithoutRef = (props: InputProps, ref: InputRef) => {
  return (
    <InputBase
      {...props}
      inputClassName="input"
      inputFocusedClassName="focused"
      inputHoveredClassName="hovered"
      inputWrapperClassName={`inputRoot  ${props.error ? "inputError" : ""}`}
      ref={ref}
      onChange={props.onChange}
    />
  );
};

export const Input = React.forwardRef(InputWithoutRef);
