import { PasswordFormContainer, TestPage, TestPageResult } from "./modules";
import { Layout } from "./components";
import { Switch, Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const RouterComponent: React.FunctionComponent = (props) => {
  const history = createBrowserHistory({ forceRefresh: true });

  return (
    <Layout>
      <Router history={history}>
        <Switch>
          <Route exact path="/testForm/:testType">
            <TestPage />
          </Route>
          <Route exact path="/result">
            <TestPageResult />
          </Route>
          <Route path="/">
            <PasswordFormContainer />
          </Route>
        </Switch>
      </Router>
    </Layout>
  );
};

function App() {
  return <RouterComponent />;
}

export default App;
