export interface HealthTest {
  type: HealthTestType;
  name: string;

  description: string;
  warning: string;
  info: string;

  language: HealthTestLanguage;
  questionsWithAnswersList: HealthTestQuestionAndAnswers[];
}

export interface HealthTestQuestionAndAnswers {
  question: string;
  answerType: AnswerType;
  answers: string[];
  isRequired: boolean;
  inputFieldName?: string | null;
}

export enum HealthTestType {
  depression = "DEPRESSION",
  parent_for_children = "PARENT_FOR_CHILDREN",
  sleep_disorder = "SLEEP_DISORDER",
  anxiety = "ANXIETY",
  ptsd = "PTSD",
  diabetes_prevention = "DIABETES_PREVENTION",
  postnatal = "POSTNATAL",
}

export enum AnswerType {
  pick = "PICK",
  input = "INPUT",
}

export enum HealthTestLanguage {
  english = "ENGLISH",
  vietnamese = "VIETNAMESE",
}

export interface CalculateHealthTestResultInput {
  type: HealthTestType;
  questionsAndAnswers: QuestionAndAnswerInput[] | [];
  additionalInput?: AdditionalInput;
}

interface AdditionalInput {
  parentForChildrenTest: ParentForChildrenTestInput;
  diabetesPreventionTest: DiabetesPreventionTestInput;
}

interface ParentForChildrenTestInput {
  childAge: number;
}

interface DiabetesPreventionTestInput {
  age: number;
  height: number;
  weight: number;
}

interface QuestionAndAnswerInput {
  question: string;
  answerType: AnswerType;
  answer: string;
}
