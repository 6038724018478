import React, { useState } from "react";

import { RadioChecked, RadioUnchecked } from "../../assets/icons";
import { useHover } from "../../hooks";

import "./Radio.scss";

type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export interface RadioProps<T extends string | number | boolean>
  extends Omit<HTMLInputProps, "value" | "onChange"> {
  label: React.ReactNode;
  value?: T;
  checked?: boolean;
  onChange?: (v: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  classNameLabel?: string;
  endAdornment?: React.ReactNode;
}

export const Radio = <T extends string | number | boolean>({
  classNameLabel,
  className,
  label,
  value,
  checked,
  onChange,
  onFocus,
  onBlur,
  endAdornment,
  ...props
}: RadioProps<T>) => {
  const [focused, setFocused] = useState(false);
  const { hovered, hoverProps } = useHover();
  const Icon = checked ? RadioChecked : RadioUnchecked;

  const color = props.disabled ? "#e2e7f1" : "#3badee";

  return (
    <label {...hoverProps} className="radioLabel">
      <div
        className={`
          radioRoot
          ${focused ? "radioFocused" : ""}
          ${hovered ? "radioFovered" : ""}
        `}
      >
        <div className="radioIcon">
          <Icon color={color} />
        </div>
        <input
          {...props}
          checked={checked}
          className="radioInput"
          type="radio"
          value={String(value)}
          onBlur={(e) => [onBlur?.(e), setFocused(false)]}
          onChange={(e) => [onChange?.(e.currentTarget.checked, e)]}
          onFocus={(e) => [onFocus?.(e), setFocused(true)]}
        />
      </div>
      <div className="radioLabelWrapper">{label}</div>
      {endAdornment}
    </label>
  );
};
