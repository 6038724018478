import "./ErrorState.css";

export function ErrorState({ error }: { error: string }) {
  return (
    <div className="error">
      <h1>Error</h1>
      <p>{error}</p>
    </div>
  );
}
